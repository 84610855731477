
import Card from 'react-bootstrap/Card';

import GlobeModal from './GlobeModal';

import { CloseIcon } from './Elements';
import { getPhotoPath } from '../utils/utils';

interface AlumniListUIProps {

    contextValues:any

}

const AlumniListUI = (props:AlumniListUIProps)=> {

    console.count('alumni list render');

    const { state, dispatch } = props.contextValues;

   
    const renderList = (alumni:any)=> {

        const { City, State } = alumni[0];

        const listHeader = (

            <div className="globe__alumniListHeader">
                <h4 style={{fontWeight:'bold', color:'white'}}>{City}{State && `, ${State}`} Alumni</h4>
                <CloseIcon handleClick={handleListClose} />
            </div>
    
        )

        const render = alumni.map((person:any, index:number) => {

            const { Name, JobTitle, GraduationYear, Photo } = person;

            const photoPath = Photo ? getPhotoPath(Photo) : null;

            return (

                <Card className="mb-2 globe__alumniCard">
                    <Card.Body>
                        <Card.Title style={{fontWeight:'bold', borderBottom:'1px solid gainsboro'}}>{ Name }</Card.Title>
                        <Card.Text>
                            <div>{ JobTitle }</div>
                            {GraduationYear && (
                                <div>Class Of { GraduationYear }</div>
                            )}
                        </Card.Text>
                        {photoPath && (

                            <div className="globe__alumniPhotoWrap">
                                <img className="globe__alumniPhoto" src={photoPath} />
                            </div>
                            
                        )}

                    </Card.Body>
                </Card>

            );

        });

        return (
            <>
            {listHeader}
            {render}
            </>
        )


    }
   

    const handleListClose = ()=> {

        console.log('list close');
        dispatch({type:'SET_ALUMNI_LIST', payload:null});

    }

    return (

        <>  
            <div style={{zIndex:90000, position:'absolute', color:'white'}}>
                
                <GlobeModal show={state?.alumniList} handleClose={handleListClose} modalClass="test">
                    {state?.alumniList && renderList(state?.alumniList)}
                </GlobeModal>
               

            </div>
        </>

    );

}

export default AlumniListUI;