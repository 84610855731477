import { Html } from '@react-three/drei';
import { LocationUIProps } from '../../interfaces/interfaces';
import { CloseIcon } from '../Elements';

import Button from 'react-bootstrap/Button';

const LocationUI = (props:LocationUIProps)=> {

    const { label, id, handleAlumniListClick, handleLocationUIClose } = props;
    
    return(

        <>
            <Html>
                <div className="globe__locationLabel animate__animated animate__fadeIn animate__faster">
                    <h6><strong style={{textTransform:'uppercase'}}>{label}</strong></h6>
                    <Button variant="primary" onClick={() => handleAlumniListClick(id)}>View Alumni</Button>
                </div>
            </Html>
        </>

    )
    

}

export default LocationUI;