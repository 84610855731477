import { Suspense, useContext, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF, useContextBridge } from '@react-three/drei';
import { earthFile, googleForm } from '../../config/config';
import { CustomOrbitControls } from './orbitControls/CustomOrbitControls';
import Globe from './Globe';
import Locations from './Locations';
import Helpers from './Helpers';
import AppIntroUI from '../AppIntroUI';
import AlumniListUI from '../AlumniListUI';
import CityListUI from '../CityListUI';
import MessageUI from '../MessageUI';

import { clientQuery, clientGet } from '../../utils/clientUtils';
import { getCityListQuery } from '../../graphql/queries';

import { GlobeContext } from '../../globe-context';
import GlobeContextProvider from '../../globe-context';

const Scene = () => {

    //passing context values manually to get around canvas + component context issue
    const { state, dispatch } = useContext(GlobeContext);

    //useGLTF.preload(earthFile);

    const sceneLoaded = useRef<boolean>(false);

    const handleGlobeLoaded = ()=> {

        console.log('globe loaded2');
        sceneLoaded.current = true;

        dispatch({type:'SET_SCENE_LOADED', payload:true});


        dispatch({type:'SET_MESSAGE', payload:`Controls:Pinch/Mousewheel to Zoom, Spin the globe and click locations to see Alumni!`})

    }

    const handleControlsInteraction = () => {

        //console.count('start')

        if(state.activeLocation) {
            dispatch({type:'SET_ACTIVE_LOCATION', payload:null});
            dispatch({type:'SET_MESSAGE', payload:null})
        }

    }

    const handleCityListClick = async ()=> {

        console.log('handleCityListClick');
        const cityListResponse = await clientQuery(getCityListQuery);

        console.log({cityListResponse});

        if(cityListResponse?.data?.locations) {

            console.log('setting city list');
            dispatch({type:'SET_CITY_LIST', payload:cityListResponse?.data?.locations});

        }


    }

    const serverCheck = async()=> {

        const serverResponse = await clientGet({suffix:'/servercheck'});
        console.log({serverResponse});

        if(serverResponse === null) {

            dispatch({type:'SET_MESSAGE', payload:'Error connecting to server..'});

        }



    }

    const renderButtons = ()=> (

        <div style={{position:'absolute', zIndex:300, top:15, width:'100%', textTransform:'uppercase'}} className="centerWrap animate__animated animate__fadeIn">
                <Button onClick={handleCityListClick}>City List</Button>
                <Button href={googleForm} target="_blank" rel="noopener noreferrer" >Alumni Form</Button>
        </div>

    )

    useEffect(()=>{

        serverCheck();

    }, []);

    return (

        <div className="globe__threeSceneWrap">

            <AlumniListUI contextValues={{ state, dispatch }} />
            <AppIntroUI contextValues={{ state, dispatch }} />

            {!state?.cityList && state.sceneLoaded && renderButtons()}

            <CityListUI contextValues={{ state, dispatch }} />
            <MessageUI contextValues={{ state, dispatch }} />

            <Canvas
                style={{height:'100vh', position:'fixed'}}
            >
                {/* <OrbitControls {...{enablePan:false, autoRotate:true}} /> */}
                <CustomOrbitControls {...{enablePan:false, autoRotate:true, maxZoom:1, onStart:handleControlsInteraction}} />
                <ambientLight />
                <pointLight position={[10,10,10]} />

                <Suspense fallback={null}>
                        <Globe handleGlobeLoaded={handleGlobeLoaded} />
                </Suspense>

                {state.sceneLoaded && (
                    <>
                        <Locations contextValues={{ state, dispatch }}/>
                    </>
                )}

            </Canvas>



        </div>

    )

}

export default Scene;