
import GlobeModal from './GlobeModal';
import { CloseIcon } from './Elements';
import { locationKeyFromLatLong } from '../utils/utils';
import { clientQuery } from '../utils/clientUtils';
import { getPeopleByLocationKeyQuery } from '../graphql/queries';

import Button from 'react-bootstrap/Button';

interface CityListUIProps {

    contextValues:any

}

const CityListUI = (props:CityListUIProps)=> {

    const { state, dispatch } = props.contextValues;

    if(!state?.cityList) {
        return null;
    }

    const handleListClose = ()=> {

        dispatch({type:'SET_CITY_LIST', payload:null});

    }

    const handleCityClick = async(locationKey:string) => {

        console.log('city click', locationKey);

        const alumniListResponse = await clientQuery(getPeopleByLocationKeyQuery(locationKey));

        if(alumniListResponse?.data) {

            const { peopleByLocationKey } = alumniListResponse?.data;
            console.log({peopleByLocationKey});
            handleListClose();
            dispatch({type:'SET_ALUMNI_LIST', payload:peopleByLocationKey});

        }

    }

    const renderCityList = ()=> {

        const list = state?.cityList.map((city:any, index:number)=> {

            const { City, Country, State, Longitude, Latitude, AlumniCount } = city;

            const locationKey = locationKeyFromLatLong(Latitude,Longitude);

            const label = Country === 'United States' ? `${City}, ${State}` : `${City}, ${Country}`;

            return (

                <div className="cityCard">
                    <h6><strong>{label}</strong></h6>
                    <Button className="buttonCompact" onClick={()=> handleCityClick(locationKey)}>See {AlumniCount} Alumni</Button>
                </div>


            )

        });


        return (

            <>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingLeft:'10', paddingRight:'10', paddingBottom:20}}>
                    <h5><strong>CITY LIST</strong></h5>
                    <CloseIcon handleClick={handleListClose} />
                </div>
                {list}
            </>

        )



    }


    return (

        <>
            <div style={{zIndex:90000, position:'absolute', color:'white'}}>

                <GlobeModal show={state?.cityList} handleClose={handleListClose} modalClass="orangeModalWrap1">
                    {state?.cityList && renderCityList()}
                </GlobeModal>

            </div>
        </>

    );


}

export default CityListUI;