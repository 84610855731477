import { useContext } from 'react';
import { LocationProps } from '../../interfaces/interfaces';
import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber'
import { Line } from '@react-three/drei'
import { degreesToRad, radToDegrees } from '../../utils/utils';
import { developerMode } from '../../config/config';

import { getPeopleByLocationKeyQuery } from '../../graphql/queries';


import LocationUI from './LocationUI';

interface LocationRayProps  {
    color:string
}


const Location = (props:LocationProps) => {

    const { active, canShowUI, isTexas, id, lat, long, handleLocationClick, handleAlumniListClick, label, color='red' } = props;

    developerMode && console.count(`location render ${label}`);

    const ref:any = useRef(null);

    const lineRef:any = useRef(null);

    const debugString:any = useRef('');

    const currentAnimation:any = useRef([0, 0, 0]);

    const globeRadius = 1;

    const radiusOffset = 1.034;

    const cameraPositionCheckVector = new THREE.Vector3(0,0,0);
    const locationPositionCheckVector = new THREE.Vector3(0,0,0);

    const viewRaycaster = new THREE.Raycaster();

    const [ hideUI, setHideUI ] = useState(false);

    const [ hover, setHover ] = useState(false);

    //let globalPosition = new THREE.Vector3(1,0,0);

    /*
    useFrame(({clock, camera})=> {

        if(false && active) {

            if(ref?.current) {


                camera.getWorldPosition(cameraPositionCheckVector);
                ref.current.getWorldPosition(locationPositionCheckVector);

                const raycastDirection = new THREE.Vector3(
                    locationPositionCheckVector.x,
                    locationPositionCheckVector.y,
                    locationPositionCheckVector.z
                );

                raycastDirection.sub(cameraPositionCheckVector).normalize();
                //viewRaycaster.set(cameraPositionCheckVector, raycastDirection);
                //const intersected = viewRaycaster.intersectObject(ref.current);

                const { x, y, z } = raycastDirection;

                //console.log([x, y, z]);


                //cheap hiding ui code

                //doesn't work great at the moment


                    if(x < -0.30 && !hideUI) {
                        setHideUI(true);
                    } else if(x > 0.30 && hideUI) {
                        setHideUI(false);
                    }


            }

        }

    });

    */



    const latLongToDegrees = (lat:number,long:number) => {

        //z = lat
        //y = long

        const yOffset = 97.45;

        const x = 0;
        const y = long - yOffset;
        const z = lat;

        //console.log({lat, long, x, y, z});

        return [x,y,z];

    }


    const LocationRay = ({color}:LocationRayProps)=> {

        return (

            <Line
                points={[
                    [0, -4, 0],
                    [0, 4, 0]
                ]}
                color={color}
                dashed={false}
            />

        )


    }


    const calculatePosition = ()=> {

        //longitude is -180 to 180
        //latitude is -90 to 90

        let globePosition = new THREE.Vector3(radiusOffset,0,0);

        const [ x, y, z] = currentAnimation.current;

        const xRad = degreesToRad(x);
        const yRad = degreesToRad(y);
        const zRad = degreesToRad(z);

        const positionEulerRotation = new THREE.Euler(xRad, yRad, zRad, 'XYZ');

        const eulerRotation = new THREE.Euler(xRad, yRad, zRad  + degreesToRad(90), 'XYZ');

        globePosition.applyEuler(positionEulerRotation);

        if(ref?.current) {

            ref.current.position.copy(globePosition);
            ref.current.rotation.copy(eulerRotation);
            ref.current.updateMatrix();
        }

    }

    //const position = calculatePosition();

    useEffect(()=>{

        //console.log('calculating position', {lat, long} );

        if(!lat || !long) {
            console.warn('location missing lat or long', label);
        }

        currentAnimation.current = latLongToDegrees(parseFloat(lat), parseFloat(long));
        calculatePosition();

    }, [ref?.current])



    const renderLocation = ()=> {

        const baseScale = isTexas ? 1.2 : 2.5;

        const scale = (hover || active) ? baseScale * 0.006 : baseScale * 0.004;
        const locationColor = (hover || active) ? '#e05928' : 'red';

        return (

            <>

                <mesh
                    scale={scale}
                    ref={ref}
                    onClick={()=> handleLocationClick(id)}
                    onPointerOver={ ()=> setHover(true)}
                    onPointerOut={ ()=> setHover(false)}
                >
                    {active && !hideUI && canShowUI && <><LocationUI label={label} id={id} handleAlumniListClick={handleAlumniListClick} /></>}

                    <sphereGeometry args={[1,10,10]} />
                    <meshPhongMaterial color={locationColor} />
                    {active && (
                        <><LocationRay color={locationColor} /></>
                    )}

                </mesh>

            </>

        );

    }

    return (

        <>
            {renderLocation()}
        </>

    );


}

export default Location;

