//Locations wrap to keep location state updates from updating globe, etc
import { useEffect, useState, useContext, useCallback, useMemo } from 'react';

import { clientQuery } from '../../utils/clientUtils';
import { keyFromLocation } from '../../utils/utils';
import { getLocationsQuery, getPeopleByLocationKeyQuery } from '../../graphql/queries';

import Location from './Location';

//import { GlobeContext } from '../../globe-context';

interface LocationProps {
    contextValues:any
}

const Locations = (props:LocationProps)=> {

    const [ activeLocation, setActiveLocation ] = useState<null|number>(null);
    const [ locations, setLocations ] = useState<null|any>(null);

    //const { state, dispatch } = useContext(GlobeContext);
    const { state, dispatch } = props.contextValues;

    const getLocations = async()=> {

        console.log('getting locations');
        const locationsResponse = await clientQuery(getLocationsQuery);
        console.log({locationsResponse});

        if(locationsResponse?.data?.locations) {
            setLocations(locationsResponse?.data?.locations);
        }

    }


    useEffect(()=> {

        getLocations();

    }, []);


    const handleAlumniListClick = async(id:number)=> {

        if(!locations?.[id]) {
            return;
        }

        const location = locations[id];

        const locationKey = keyFromLocation(location);

        console.log('ui list click', {location, locationKey});

        //todo: duplicated in citylist
        const alumniListResponse = await clientQuery(getPeopleByLocationKeyQuery(locationKey));

        if(alumniListResponse?.data) {

            const { peopleByLocationKey } = alumniListResponse?.data;
            console.log({peopleByLocationKey});
            dispatch({type:'SET_ALUMNI_LIST', payload:peopleByLocationKey});

        }

    }

    const handleLocationUIClose = ()=> {

        console.log('handle ui location close');
        dispatch({type:'SET_ACTIVE_LOCATION', payload:null});

    }


    const renderLocations = ()=> {

        const render = locations.map((location:any,index:number) => {

            const { City, Country, State, Latitude, Longitude, IsTexas } = location;

            const label = Country !== 'United States' ? `${City}, ${Country}` : City;

            const canShowUI = !state?.alumniList;
            const active = state.activeLocation === index;

            //const isTexas = State && ['tx', 'texas'].includes(State.trim().toLowerCase());

            //const isTexas = IsTexas === "true";

            /*
            const locationMemo = useMemo(<Location canShowUI={canShowUI} active={active} handleLocationClick={handleLocationClick} handleAlumniListClick={handleAlumniListClick} id={index} label={label} lat={Latitude} long={Longitude} />, [
                canShowUI, active, index, label, Latitude, Longitude
            ]);
            */

            return <><Location canShowUI={canShowUI} active={state.activeLocation === index} handleLocationClick={handleLocationClick} handleLocationUIClose={handleLocationUIClose} handleAlumniListClick={handleAlumniListClick} id={index} label={label} lat={Latitude} long={Longitude} isTexas={IsTexas} /></>

            //return <>{locationMemo}</>
        });

        return <>{render}</>
    }


    const handleLocationClick = (id:number) => {

        console.log('location(s) click', id);
        console.log({state, dispatch});
        dispatch({type:'SET_ACTIVE_LOCATION', payload:id});

    }



    return (

        <>
           {locations && renderLocations()}
        </>

    );

}

export default Locations;