import { useEffect } from 'react';

import { clientQuery } from '../utils/clientUtils';
import { getLocationsQuery } from '../graphql/queries';


const Testing = () => {


    const getLocations = async()=> {

        console.log('getting locations');
        const locationsResponse = await clientQuery(getLocationsQuery);
        console.log('testing');
        console.log({locationsResponse});
        
    }


    useEffect(()=> {

        getLocations();

    }, [])

    return (

        <div>
            <h2>Testing</h2>


        </div>

    )



}

export default Testing;