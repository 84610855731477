import { Html } from '@react-three/drei';

const DebugUI = (props:any)=> {

    const { text } = props;

    return(

        <>
            <Html>
                <div style={{borderRadius:15, backgroundColor:'black', color:'white', padding:10}} >
                    {text}
                </div>
            </Html>
        </>

    )
    
}

export default DebugUI;