import { XCircle } from 'react-feather';

interface CloseIconInterface {
    handleClick:any
}


export const CloseIcon = (props:CloseIconInterface) =>  {

    return (

        <div className="closeIcon" onClick={props?.handleClick} style={{textAlign:'right'}}>
            <XCircle color="white" size={37} />
        </div>

    )

}

export const message = ()=> {
    return <div>message</div>;
}