import { stat } from "fs";

interface PayloadProps {
    type:string,
    payload:any
}

export default function reducer(state:any, { type, payload }: PayloadProps) {

    console.log('dispatch', {type, payload});

    switch(type) {

        case 'SET_ALUMNI_LIST':
            return { ...state, alumniList:payload };

        case 'SET_CITY_LIST':
            return { ...state, cityList:payload };

        case 'SET_MESSAGE':
            return { ...state, message:payload };

        case 'SET_ACTIVE_LOCATION':
            return { ...state, activeLocation:payload };

        case 'SET_SCENE_LOADED':
            return { ...state, sceneLoaded:payload };

        default:
            return state;

    }

}