import { degreesToRad } from '../../utils/utils';

interface RingProps {

    rotationX:number,
    rotationY?:number,
    color:string

}


const Helpers = () => {

    const Ring = (props:RingProps)=> {

        const { rotationX=0, rotationY=0, color } = props;

        return(

            <>
                <mesh
                    scale={0.45}
                    rotation={[rotationX,rotationY,0]}
                >
                    <ringGeometry args={[1,3,90]} />
                    <meshPhongMaterial color={color} wireframe />
                </mesh>
            </>

        )

    }

    return (

        <>
            <Ring rotationX={0} rotationY={degreesToRad(84)} color={'blue'} />
            <Ring rotationX={degreesToRad(90)} color={'green'} />
        </>

    )

}

export default Helpers;