//adapted from youtube context example

import { createContext, useReducer } from 'react';
import globeReducer from './globe-reducer';

const initialState:any = {

    message:null,
    alumniList:null,
    cityList:null,
    activeLocation:null,
    sceneLoaded:false

};

export const GlobeContext = createContext(initialState);


const GlobeContextProvider = (props:any) => {

    const { children } = props;

    const [ state, dispatch ] = useReducer(globeReducer, initialState);

    /*
    const contextValues:any = {
        ...state,
    }
    */

    return (

        <GlobeContext.Provider value={{ state, dispatch }}>

            {
                children
            }

        </GlobeContext.Provider>

    );

}

export default GlobeContextProvider;