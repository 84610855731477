import { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Line, Html } from '@react-three/drei';
import Helpers from '../components/three/Helpers';
import * as THREE from 'three';

import DebugUI from '../components/three/DebugUI';

//import { getObjectRotationRelativeToCamera } from '../utils/utils';


const TestingViewAngle = () => {

    const [ debugText, setDebugText ] = useState('')
    
    const cameraPositionCheckVector = new THREE.Vector3(0,0,0);
    const locationPositionCheckVector = new THREE.Vector3(0,0,0);
    
    const globeRef = useRef<any>();

    const TestSphere = ()=> {

        useFrame(({clock, camera})=> {

            
            /*
                camera.getWorldPosition(cameraPositionCheckVector);
                const {x,y,z} = cameraPositionCheckVector;

                debugText.current = JSON.stringify([x,y,z]);
            
             */

            if(globeRef?.current) {
                
                //const rot = getObjectRotationRelativeToCamera(camera, globeRef.current);

                //var rot = camera.getWorldQuaternion();
                //rot.multiply(obj.getWorldQuaternion().inverse());
                //var euler = new THREE.Euler().setFromQuaternion(rot);

                const cameraQuaternionWorld = new THREE.Quaternion();
                const objectQuaternionWorld = new THREE.Quaternion();

                camera.getWorldQuaternion(cameraQuaternionWorld);
                globeRef?.current.getWorldQuaternion(objectQuaternionWorld);

                cameraQuaternionWorld.multiply(objectQuaternionWorld.invert());

                const euler = new THREE.Euler().setFromQuaternion(cameraQuaternionWorld);
                console.log(euler);
                const { x,y,z} = euler;
                setDebugText(JSON.stringify([x,y,z]));


            }
           
            
        
        });

        return (

            <>
            
                <mesh
                    ref={globeRef}
                    scale={[0.4, 0.4, 0.4]}
                    
                >
                    <sphereGeometry args={[1,10,10]} />
                    <meshPhongMaterial color={'green'} wireframe />
                </mesh>
                
                    

            </>

        )

    }

    const Pole = ()=> {

        return (

            <Line 
                        points={[
                            [0, 0, 0],
                            [0, 1, 0]
                        ]}
                        color="yellow"
                        dashed={false}
            />

        )

    }

    return (

        <div className="globe__threeSceneWrap">
            <>
                <Canvas>
                    <ambientLight />
                    <pointLight position={[10,10,10]} />

                    
                    
                    <TestSphere/>
                    <OrbitControls/>

                    <DebugUI text={debugText}/>
                    
                    <Helpers/>
                </Canvas>
            </>
        </div>

    )

}

export default TestingViewAngle;