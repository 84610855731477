import { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';

import { burkISDLogo } from '../config/config';

interface AppIntroUIProps {

    contextValues:any

}

const AppIntroUI = (props:AppIntroUIProps)=> {

    const { contextValues } = props;
    const { state, dispatch } = contextValues;

    const [ hideScene, setHideScene ] = useState<boolean>(false);
    

    if(hideScene) {
        return null;
    }

    if(state?.sceneLoaded) {

        setTimeout(() => {
            setHideScene(true);
        }, 1200);

    }

    return (

        <div className={`globe__introWrap ${state?.sceneLoaded ? 'animate__animated animate__fadeOut' : ''}`}>

            <img src={burkISDLogo} className="burkISDLogo" />
        
            <h2>Burkburnett ISD</h2>
            <h1>Alumni Around The World</h1>
            <h6> LOADING</h6>
            <BeatLoader color="white"/>
        </div>

    );


}

export default AppIntroUI;