import React from 'react';

import {

  BrowserRouter as Router,
  Routes,
  Route,

} from 'react-router-dom';


import logo from './logo.svg';
import './App.css';
import './styling/style.scss'


//import Scene from './components/three/Scene';
import MainPage from './pages/MainPage';
import Testing from './pages/Testing';
import TestingViewAngle from './pages/TestingViewAngle';
import DebugEntries from './pages/DebugEntriesPage';

function App() {
  return (

    <Router>
      <div className="App">

        <Routes>

          <Route path='/testing' element={<Testing/>} />
          <Route path='/testingviewangle' element={<TestingViewAngle />}/>
          <Route path='/debugentriespage' element={<DebugEntries />} />
          <Route path='/' element={<MainPage />}/>

        </Routes>


      </div>
    </Router>
  );
}

export default App;
