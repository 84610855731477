import { useState, useEffect } from 'react';

import { clientQuery } from '../utils/clientUtils';
import { getPhotoPath } from '../utils/utils';
import { developerMode } from '../config/config';
import { getPeopleWithPhotosQuery } from '../graphql/queries';

const DebugEntriesPage = ()=> {

    const [ people, setPeople ] = useState<any>(null);

    const getPeople = async()=> {

        const peopleResponse = await clientQuery(getPeopleWithPhotosQuery);
        console.log(peopleResponse);
        if(peopleResponse?.data?.peopleWithPhotos) {

            console.log('setting people');
            setPeople(peopleResponse?.data?.peopleWithPhotos);

        }

    }


    useEffect(() => {
        if(developerMode) {
            getPeople();
        } else {
            console.error('not developer mode, not getting query');
        }
    }, [])


    const renderPeople = ()=> {

        return people.map((person:any) => {

            const { Name, Photo, AddressFound } = person;

            const preppedImagePath = getPhotoPath(Photo);

            return (

                <div style={{display:'flex', flexDirection:'column', border:'2px solid gainsboro', borderRadius:15, padding:20, marginBottom:20}}>
                    <h4>{Name}</h4>
                    {preppedImagePath}
                    <img style={{width:300}} src={preppedImagePath} />

                </div>

            );


        });



    }

    return (

        <div>
            people photo check
            {people && renderPeople()}

        </div>

    )



}

export default DebugEntriesPage;