
import Scene from '../components/three/Scene';
import AlumniListUI from '../components/AlumniListUI';
import GlobeContextProvider from '../globe-context';

const MainPage = ()=> {

    return (

        <div>
            {/* <AppIntro/> */}
            <GlobeContextProvider>
                <Scene/> 
            </GlobeContextProvider>
        </div>

    );

}

export default MainPage;
