
import axios from 'axios';

import { serverEndpoint } from '../config/config';

const graphqlEndpoint = `${serverEndpoint}/graphql`;

//axios.defaults.withCredentials = true;

export const clientQuery = async(query) => {

    console.log({query});

    try {

        const response = await axios.post(graphqlEndpoint, {query});

        if(response?.data) {

            console.log(response.data);
            return response.data;

        } else {

            console.log('no data response', response);
            return null;
        }



    } catch(err) {


        console.log(`clientQuery error`, err);
        return null;
    }




}

export const clientGet = async({suffix}) => {

    if(!suffix) {
        console.error('client get missing suffix');
        return null;
    }

    try {

        const response = await axios.get(`${serverEndpoint}${suffix}`);

        if(response?.data) {
            return response.data;
        } else {
            console.log('client get error', suffix);
        }


    }catch(err) {

        console.log('client get error', err);
        return null;

    }



}