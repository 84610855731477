
const developerMode = false;

const serverEndpoint = developerMode ? `http://${window.location.hostname}:4000` : 'https://burk-globe-server.sites2.dwg-production.xyz';

const filesRootPath = 'https://dwg-spaces.sfo2.cdn.digitaloceanspaces.com/burk-globe';

const earthFile = `${filesRootPath}/GlobalPipelineExport01.glb`;
const burkISDLogo = `${filesRootPath}/burk-isd-logo1.png`;

const googleForm = 'https://docs.google.com/forms/d/e/1FAIpQLSfGDJnTSZcdiPURoK-wEODZSiiq9jChbd_z3QRXJ8QqkdZdmw/viewform?usp=sf_link';

const submittedPhotosPath = `${filesRootPath}/submission-assets/submitted-photos/`

module.exports = {

    developerMode,
    serverEndpoint,
    earthFile,
    googleForm,
    submittedPhotosPath,
    burkISDLogo

}