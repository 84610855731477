import { Suspense, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber'
import { useGLTF, Line } from '@react-three/drei'
import { earthFile } from '../../config/config';

interface GlobeProps {

    handleGlobeLoaded:()=> void
}

const Globe = (props:GlobeProps)=> {

    const { handleGlobeLoaded} = props;

    const ref:any = useRef();

    const sceneLoaded = useRef<boolean>(false);

    const { scene } = useGLTF(earthFile)

    const globeRotationOffset = [0.1,0.1,-0.5];

    useFrame(({clock})=> {

        if(ref?.current) {

            //ref.current.rotation.y = clock.getElapsedTime()

        }

    });

    useEffect(()=> {

        if(scene && !sceneLoaded.current) {
            sceneLoaded.current = true;
            handleGlobeLoaded();
        }

    }, [scene])

    const DebugPole = ()=> {

        return (

            <Line
                points={[
                    [0, -2, 0],
                    [0, 2, 0]
                ]}
                color="yellow"
                dashed={false}
            />

        )


    }


    return (

        <>

            {/*
            <mesh
                ref={ref}
                scale={2}
            >
            <sphereGeometry args={[1,20,20]} />
            <meshPhongMaterial color={'blue'} wireframe />

            </mesh>
            */}

            {/* <DebugPole/> */}

            <primitive object={scene} rotation={globeRotationOffset} />


        </>

    )

    //useGLTF.preload(gltfPath);

}

export default Globe;

//useGLTF.preload(earthFile);