
//https://www.w3resource.com/javascript-exercises/javascript-math-exercise-33.php
import { submittedPhotosPath } from '../config/config';

export const degreesToRad = (degrees) =>
{
    var pi = Math.PI;
    return degrees * (pi/180);
}

export const radToDegrees = (rads) =>
{
    var pi = Math.PI;
    return rads * (180/pi);
}

export const keyFromLocation = location => {
    const { City, Latitude, Longitude } = location;
    //const key = `${Latitude},${Longitude}`;
    const key = locationKeyFromLatLong(Latitude,Longitude);
    console.log({City, key});
    return key;
}


export const locationKeyFromLatLong = (latitude, longitude) => `${latitude},${longitude}`;


export const getPhotoPath = filename => {

    let file = filename;
    if(file.includes('.')) {
        file = filename.slice(0,filename.lastIndexOf('.'));
    }

    //const preppedFilename = encodeURIComponent(`${filename.split('.')[0]}.jpg`);
    const preppedFilename = `${encodeURIComponent(file)}.jpg`;
    return `${submittedPhotosPath}${preppedFilename}`;

}

//https://stackoverflow.com/questions/47917597/three-js-how-to-get-an-objects-rotation-relative-to-another

/*
export const getObjectRotationRelativeToCamera = (camera, obj, cameraQuaterion, targetQuaterion)=> {

    if(!camera || !obj) {
        console.log('error missing properties', {camera, obj});
        return null;
    }

    //var rot = camera.getWorldQuaternion();
    //rot.multiply(obj.getWorldQuaternion().inverse());
    //var euler = new THREE.Euler().setFromQuaternion(rot);

    camera.getWorldQuaterion(targetQuaterion);
    targetQuaterion.multiply(obj.getWorldQuaternion().inverse());


    //return rot;

}
*/

