
interface MessageUIProps {

    contextValues:any

}

const MessageUI = (props:MessageUIProps) => {

    const { state, dispatch } = props.contextValues;

    if(!state?.message) {
        return null;
    }

    return (

        <div style={{width:'100%', display:'flex', justifyContent:'center', position:'absolute', marginTop:'70px'}}>
            <div className="globe__messageUI animate__animated animate__fadeIn">{state?.message}</div>
        </div>


    )


}


export default MessageUI;
