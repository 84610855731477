
const getLocationsQuery = `

    query Locations{
        locations {
            City
            State
            Country
            Latitude
            Longitude
            IsTexas
        }
    }

`;

const getCityListQuery = `

    query CityList {
        locations {
            City
            State
            Country
            AlumniCount
            Latitude
            Longitude
            IsTexas
        }
    }

`;

const getPeopleByLocationKeyQuery = (locationKey) => `

    query GetPeopleByLocationKeyQuery {

        peopleByLocationKey(locationKey: "${locationKey}") {

          Name
          City
          State
          JobTitle
          GraduationYear
          Photo

        }

    }

`;

const getPeopleWithPhotosQuery = `

    {
        peopleWithPhotos {
        Photo
        Name
        AddressFound
        }
    }

`;


module.exports = {

    getLocationsQuery,
    getCityListQuery,
    getPeopleByLocationKeyQuery,
    getPeopleWithPhotosQuery

};