
import { ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { GlobeModalProps } from '../interfaces/interfaces';

const GlobeModal = (props:GlobeModalProps) => {


    const { show, handleClose, modalClass } = props;

    return (

        <div className={modalClass}>
            <Modal className={modalClass} show={show} onHide={handleClose}>
                <ModalBody>
                {props?.children}
                </ModalBody>
            </Modal>
        </div>
    )

}

export default GlobeModal;